import {
	SimpleGrid,
	SimpleGridProps,
	Grid as ChakraGrid,
	GridItem,
	GridProps,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { gap } from "../../theme/brandThemes/baseTheme";

export const EvenGrid: ReactFC<SimpleGridProps> = ({ children, ...rest }) => {
	const { columns, fillers } = useMemo(() => {
		const columns = React.Children.count(children);
		const fillers =
			columns % 2 === 0 ? [] : [...new Array(Math.max(4 - columns, 0))];

		return { columns, fillers };
	}, [children]);

	return (
		<SimpleGrid
			minChildWidth={{
				base: "100%", // full width
				smmd: `calc(50% - ${gap})`, // 1/2
				lgxl: `calc(25% - (0.75 * ${gap}))`, // 1/4
			}}
			columns={columns}
			spacing="grid.gap"
			{...rest}
		>
			{children}
			{fillers.map((_, i) => (
				// eslint-disable-next-line react/no-array-index-key
				<div key={i} />
			))}
		</SimpleGrid>
	);
};

export { GridItem };

export const Grid: ReactFC<GridProps> = ({ children, ...rest }) => {
	return (
		<ChakraGrid gap="grid.gap" {...rest}>
			{children}
		</ChakraGrid>
	);
};
