import {
	createElement,
	Fragment,
	ReactChild,
	ReactElement,
	useMemo,
} from "react";

const getReplacedParts = (
	template: string,
	replacements: Record<string, string | ReactChild>,
) => {
	const entries = Object.entries(replacements);
	const re = /({\w+})/g;

	return template.split(re).map((str) => {
		const replace = entries.find((r) => `{${r[0]}}` === str)?.[1];

		return replace ?? (re.test(str) ? "" : str);
	});
};

export const replaceTemplateStringsPlaceholders = (
	template: string,
	replacements: Record<string, string>,
): string => {
	const parts = getReplacedParts(template, replacements);

	return parts.join("");
};

export const replaceTemplateStringsPlaceholdersReact = (
	template: string,
	replacements: Record<string, ReactElement | string | number>,
): ReactElement => {
	const parts = getReplacedParts(template, replacements);

	return createElement(
		Fragment,
		{},

		parts.map(
			// eslint-disable-next-line react/no-array-index-key
			(p, key): ReactElement => createElement(Fragment, { key }, p),
		),
	);
};

export const useTemplateStrings = (
	...args: Parameters<typeof replaceTemplateStringsPlaceholdersReact>
) => useMemo(() => replaceTemplateStringsPlaceholdersReact(...args), [args]);
