import React, { ReactChild, useMemo } from "react";
import {
	Box,
	chakra,
	Container,
	Flex,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import { BrandSettings } from "../../../lib/brandSettings";
import { truthy } from "../../../utils/misc";
import { Maybe } from "../../../__generated__/types/graphql-codegen";
import { HeadingLevelBoundary } from "../../../components/headings";
import { Link } from "../../../components/Link/Link";
import { useTemplateStrings } from "../../../utils/templateStrings";
import { useRewriteContext } from "../../../context/RewriteContext";
import { Grid, GridItem } from "../../../components/Grid/Grid";
import { useTranslatedString } from "../../../i18n/hooks";
import { useBrandLogo } from "../../../brandComponents";
import { OpenConsentButton } from "../../../components/OpenConsentButton/OpenConsentButton";
import { useTenClicksToStartEditorTools } from "../../../components/EditorTools/EditorTools";

export type FooterProps = {
	columns: NonNullable<BrandSettings>["footerMenu"];
	copyright?: Maybe<string>;
	desktopBreakpoint?: string;
};

export const Footer = ({
	columns,
	copyright: copyrightTpl,
	desktopBreakpoint = "lg",
}: FooterProps) => {
	const t = useTranslatedString();
	const thisBrand = useRewriteContext();

	const {
		container: __container,
		link: __link,
		// heading: __heading,
		copyright: __copyright,
		logo: __logo,
	} = useMultiStyleConfig("Footer", {
		desktopBreakpoint,
		variant: thisBrand.pageType,
	});

	const router = useRouter();

	const brandName: ReactChild = useMemo(() => {
		if (router.pathname === "/[brand]") {
			return <chakra.h1 display="inline">{thisBrand.label}</chakra.h1>;
		}

		return thisBrand.label;
	}, [thisBrand.label, router.pathname]);

	const copyright = useTemplateStrings(copyrightTpl ?? "", {
		year: new Date().getFullYear(),
		brandName,
		version: process.env.MMG_VERSION ?? "",
	});

	const Logo = useBrandLogo(undefined, true);

	const handleCopyrightClick = useTenClicksToStartEditorTools();

	const unifiedColumnLink = [
		...columns
			.filter(truthy)
			.map(({ links }) => links)
			.flat()
			.map((l) => ({
				...l,
				brand:
					l.__typename === "ArticleRecord"
						? l.articleBrand
						: l.pageBrand,
			})),
	];

	return (
		<HeadingLevelBoundary>
			<chakra.footer
				data-datocms-noindex
				__css={__container}
				data-cy="footer"
			>
				<Container>
					<Grid templateColumns="repeat(12, 1fr)">
						<GridItem
							colSpan={{
								base: 12,
								sm: 5,
								[desktopBreakpoint]: 8,
							}}
							display="flex"
							flexFlow="column nowrap"
							justifyContent="space-between"
						>
							<Link href="/">
								<Logo aria-label={t("home")} sx={__logo} />
							</Link>
						</GridItem>
						{/* Commented out until there is enough content to fill two columns and MMG wants it back */}
						{/* <GridItem
							colSpan={{ base: 12, [desktopBreakpoint]: 4 }}
						>
							<Grid
								templateColumns={{ base: "1fr", sm: "1fr 1fr" }}
							>
								{columns
									.filter(truthy)
									.slice(0, 2)
									.map(({ id, heading, links }) => (
										<GridItem
											key={id}
											colSpan={{ base: 1 }}
										>
											{heading && (
												<ChakraHx
													w="100%"
													__css={__heading}
												>
													{heading}
												</ChakraHx>
											)}

											{links.map(
												({ slug, title, brand }) =>
													slug &&
													title && (
														<Link
															data-cy="footer-link"
															brand={
																(brand ??
																	undefined) as
																	| BrandSlug
																	| undefined
															}
															sx={__link}
															key={slug}
															href={`/${slug}/`}
															display="block"
														>
															{title}
														</Link>
													)
											)}
											{heading === "Company" && (
												<OpenConsentButton
													sx={__link}
													variant="inline-link"
													data-cy="data-privacy-settings-button"
												>
													{t("dataPrivacySettings")}
												</OpenConsentButton>
											)}
										</GridItem>
									))}
							</Grid>
						</GridItem> */}
						<GridItem colSpan={12}>
							<Flex
								flexDirection={{
									base: "column-reverse",
									md: "row",
								}}
								gap="6"
								justifyContent="space-between"
							>
								<Box>
									<chakra.small
										__css={__copyright}
										onClick={handleCopyrightClick}
									>
										{copyright}
									</chakra.small>
								</Box>
								<Flex flexDirection="row" gap="4">
									{unifiedColumnLink.map(
										({ slug, title, brand }) =>
											slug &&
											title && (
												<Link
													data-cy="footer-link"
													brand={
														brand?.slug ??
														thisBrand.slug
													}
													// overwriting the link style margin for this temporary footer design
													sx={{
														...__link,
														mb: 0,
													}}
													key={slug}
													href={`/${slug}/`}
													display="block"
												>
													{title}
												</Link>
											),
									)}
									<OpenConsentButton
										sx={__link}
										variant="inline-link"
										data-cy="data-privacy-settings-button"
									>
										{t("dataPrivacySettings")}
									</OpenConsentButton>
								</Flex>
							</Flex>
						</GridItem>
					</Grid>
				</Container>
			</chakra.footer>
		</HeadingLevelBoundary>
	);
};
