import { useCounter } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import { useEffect } from "react";
import { useLocalStorage } from "react-use";
import type { EditorToolsUIProps } from "./EditorToolsUI";

const EditorToolsUI = dynamic<EditorToolsUIProps>(async () =>
	import("./EditorToolsUI").then((mod) => mod.EditorToolsUI),
);

export const useEditorTools = () =>
	useLocalStorage<string>("MMG_EditorTools", "");

export const EditorTools: ReactFC<{ isPreview: boolean }> = ({ isPreview }) => {
	const [token] = useEditorTools();

	return token && token.length > 0 ? (
		<EditorToolsUI isPreview={isPreview} token={token} />
	) : null;
};

export const useTenClicksToStartEditorTools = () => {
	const { increment, value } = useCounter({ max: 10 });
	const [, setActive] = useEditorTools();

	useEffect(() => {
		if (Number(value) >= 10) {
			// eslint-disable-next-line no-alert
			const secret = prompt("Token");

			if (!secret) {
				return;
			}

			setActive(secret);
			location.reload();
		}
	}, [setActive, value]);

	return () => increment();
};
